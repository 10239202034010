import React from 'react';

const IconLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 84 96">
    <title>Logo</title>   
    <g id="Logo" transform="translate(-8.000000, -2.000000)">
      <g transform="translate(11.000000, 5.000000)">
      <path fill="#F5F2F2" stroke="#F5F2F2" strokeWidth="2" d="M650.32,650.07h-4.62v-29.31h-11.45v-3.71h27.52v3.71h-11.45V650.07z" transform="translate(-606.63 -588.46)"/>
        <polygon
          id="Shape"
          stroke="#F5F2F2"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
          points="39 0 0 22 0 67 39 90 78 68 78 23"
        />
      </g>
    </g>
  </svg>
);

export default IconLogo;
